import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, catchError, filter, forkJoin, Observable, of, tap } from 'rxjs';
import { HOST_NAME } from 'src/app/global.tokens';
import { test_userDocumentsResponce, typeMapping, UserDocumentsResponse, DocumentData, Status, InputFile, sectionsData, TranslatedStatus, SectionCard, SectionCardDocument, SectionCardFile, SectionCardCookie, baseDocumentCookie, UserDocumentSection, SectionCardCookieElem } from './upload-documents.models';
import { CustomEncoder } from 'src/app/interceptors/encode-http.interceptor';
import { DOWNLOAD_DOCUMENT, GET_DOCUMENT, UPLOAD_DOCUMENTS, USER_DOCUMENTS } from '../http/base-http.service';
import { BaseUsermgrService } from '../http/base-usermgr.service';

@Injectable({
  providedIn: 'root'
})
export class UploadDocumentsService extends BaseUsermgrService {

  private userDocumentsResponse = new BehaviorSubject<UserDocumentsResponse>(null);
  private sectionCardsSubject = new BehaviorSubject<SectionCard[]>(sectionsData);
  //  private sectionCardsCookieSubject = new BehaviorSubject<SectionCardCookie>(baseDocumentCookie); ?

  constructor(
    http: HttpClient,
    cookie: CookieService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(HOST_NAME) public hostName,
  ) {
    super(http, cookie, locale, hostName);

    //localStorage.clear()

    //this.updateUserDocuments();
  }

  public updateUserDocuments(): void {
    this.getDocuments().pipe(
      tap((userDocumentsResponse) => this.userDocumentsResponse.next(userDocumentsResponse)),
      //tap((userDocumentsResponse) => this.userDocumentsResponse.next(test_userDocumentsResponce)),
      catchError(error => {
        console.error('Error fetching user documents', error);
        return of(null);
      })
    ).subscribe();
  }

  public getUserDocuments(): Observable<UserDocumentsResponse> {
    return this.userDocumentsResponse.asObservable().pipe(filter((userDocuments) => userDocuments !== null));
  }

  public updateSectionCards(sectionCards: SectionCard[]): void {
    this.sectionCardsSubject.next(sectionCards);
  }

  public getSectionCards(): Observable<SectionCard[]> {
    return this.sectionCardsSubject.asObservable().pipe(filter((sectionCards) => sectionCards !== null));
  }

  public updateSectionsCookie(section_card_cookie: SectionCardCookie): void {
    localStorage.setItem('sectionCardsCookie', JSON.stringify(section_card_cookie));
  }

  public getSectionsCookie(): SectionCardCookie {
    return localStorage.getItem('sectionCardsCookie') ? JSON.parse(localStorage.getItem('sectionCardsCookie')) : baseDocumentCookie;
  }


  public isShowSections(userDocuments: UserDocumentsResponse): boolean {
    const hideTabContent = userDocuments.hide_tab_content;
  
    const hideNotRequired = Object.entries(userDocuments.hide_not_required)
      .some(([key, value]) => value !== true && userDocuments.status[key] !== Status.NotRequired);
  
    const allNotRequired = Object.entries(userDocuments.status)
      .every(([key, value]) => value === Status.NotRequired || userDocuments.hide_not_required[key]);
  
    return !(hideTabContent || !hideNotRequired || allNotRequired);
  }


  private fillSectionDocuments(section: SectionCard, userDocuments: UserDocumentsResponse): void {
    section.status = this.translateStatus(userDocuments.status[section.type]);
    section.card_documents = Array.isArray(userDocuments.documents[section.type])
      ? userDocuments.documents[section.type].map((document, index) => ({
          id: index,
          status: this.translateStatus(document.status as Status),
          name: document.name,
          created: document.created
        }))
      : [];
  }

  private fillSectionCookie(section: SectionCard, sectionCookie: SectionCardCookieElem[]) {
    section.card_documents = section.card_documents.filter((doc) => {
      const cookieDoc = sectionCookie.find((cookieDoc) => cookieDoc.name === doc.name);
  
      if (cookieDoc && cookieDoc.hide) {
        return false;
      }
  
      doc.label_id = cookieDoc ? cookieDoc.label_id : -1;
      return true;
    });
  }

  private setDisplayStatus(section: SectionCard) {
    const loadedDocuments = section.card_documents.filter((doc) => doc.label_id !== -1);

    if (loadedDocuments.length >= section.max_section_load * section.card_files.length) {
      const t_card_documents = section.card_documents.slice(0, (section.max_section_load * section.card_files.length * 2));

      const loadedDocuments = t_card_documents.filter((doc) => (doc.status === this.translateStatus(Status.Approved) || doc.status === this.translateStatus(Status.Pending)) && doc.label_id !== -1);
      const aprPenHistoryDocs = t_card_documents.filter((doc) => (doc.status === this.translateStatus(Status.Approved) || doc.status === this.translateStatus(Status.Pending)) && doc.label_id === -1);    
      const otherloadedDocs = t_card_documents.filter((doc) => !(doc.status === this.translateStatus(Status.Approved) || doc.status === this.translateStatus(Status.Pending)) && doc.label_id !== -1); 
      const otherHistoryDocs = t_card_documents.filter((doc) => !(doc.status === this.translateStatus(Status.Approved) || doc.status === this.translateStatus(Status.Pending)) && doc.label_id === -1);

      section.card_documents = [...loadedDocuments, ...aprPenHistoryDocs, ...otherloadedDocs, ...otherHistoryDocs].slice(0, (section.max_section_load * section.card_files.length)).reverse();

    } else if (loadedDocuments.length > 0 && loadedDocuments.length < section.max_section_load * section.card_files.length) {
      const t_card_documents = section.card_documents.slice(0, (section.max_section_load * section.card_files.length + loadedDocuments.length));

      const aprPenHistoryDocs = t_card_documents.filter((doc) => (doc.status === this.translateStatus(Status.Approved) || doc.status === this.translateStatus(Status.Pending)) && doc.label_id === -1);
      const otherHistoryDocs = t_card_documents.filter((doc) => !(doc.status === this.translateStatus(Status.Approved) || doc.status === this.translateStatus(Status.Pending)) && doc.label_id === -1);

      section.card_documents = [...loadedDocuments, ...aprPenHistoryDocs, ...otherHistoryDocs].slice(0, (section.max_section_load * section.card_files.length)).reverse();

    } else {
      const t_card_documents = section.card_documents.slice(0, (section.max_section_load * section.card_files.length));

      const aprPenHistoryDocs = t_card_documents.filter((doc) => doc.status === this.translateStatus(Status.Approved) || doc.status === this.translateStatus(Status.Pending));
      const otherHistoryDocs = t_card_documents.filter((doc) => !(doc.status === this.translateStatus(Status.Approved) || doc.status === this.translateStatus(Status.Pending)));

      section.card_documents = [...aprPenHistoryDocs, ...otherHistoryDocs].reverse();
    }

    section.card_files.forEach((card_file) => {
      section.card_documents.forEach((doc) => {
        if (doc.status === this.translateStatus(Status.Pending) || doc.status === this.translateStatus(Status.Approved)) {
          //console.log(section, doc.status, doc)
          if (doc.label_id == card_file.id) {
            card_file.load_value++;
          } else if (doc.label_id == -1) {
            if (section.max_section_load > card_file.load_value) {
              card_file.load_value ++;
              doc.label_id = card_file.id
            }
            
          }
          
        }
      });

      card_file.load_value >= section.max_section_load ? card_file.stage = -1 : card_file.stage = 0;

    })

    if (section.status === this.translateStatus(Status.Approved)) {
      section.card_files.forEach((card_file) => {
        card_file.stage = -1;
      })
    }

  }

  public fillSectionCards(sectionCards: SectionCard[], userDocuments: UserDocumentsResponse, sectionCardsCookie: SectionCardCookie): SectionCard[] {
    sectionCards.forEach((section) => {
      if (section.card_documents.length > 0) return

      this.fillSectionDocuments(section, userDocuments);
      this.fillSectionCookie(section, sectionCardsCookie[section.type]);
      this.setDisplayStatus(section);

      section.is_hide = userDocuments.hide_not_required[section.type];
      section.status = this.translateStatus(userDocuments.status[section.type] as Status);
    });

    return sectionCards
  }

  createFileUploadHeaders(headers: {
    [name: string]: string | string[];
  } = {}): HttpHeaders {
    const newHeaders = {};
    Object.assign(newHeaders, headers);
    const csrfToken = this.cookie.get('csrftoken');
    newHeaders['Content-language'] = this.locale;
    newHeaders['Accept-Language'] = `pl-PL,${this.locale}`;
    newHeaders['x-translation-lang'] = this.locale?.slice(0, 2);
    if (csrfToken) {
      newHeaders['X-CSRFToken'] = csrfToken;
    }
    return new HttpHeaders(newHeaders);
  }

  uploadDocument(card_file: SectionCardFile) {
    const docForm = new FormData();
    const file = card_file.file;

    docForm.append('document_type', card_file.type);
    docForm.append('file-1', file);

    const httpOptions = {
      headers: this.createFileUploadHeaders(),
      withCredentials: true
    };

    console.log(docForm.get('file-1'), docForm.get('document_type'));
    //return of(true)
    return this.http.post<any>(this.apiUrl + UPLOAD_DOCUMENTS, docForm, httpOptions);
  }

  getDocuments() {
    return this.get<any>(USER_DOCUMENTS);
  }

  getDocument(docId: string) {
    const url = GET_DOCUMENT + docId
    return this.get<any>(url)
  }

  downloadDocument(docId: string) {
    const url = DOWNLOAD_DOCUMENT + docId
    return this.get<any>(url)
  }

  // Function to map Status to TranslatedStatus
  translateStatus(status: Status): string {
    const translationMap: { [key in Status]: string } = {
      [Status.NotRequired]: TranslatedStatus.NotRequired,
      [Status.Required]: TranslatedStatus.Required,
      [Status.Approved]: TranslatedStatus.Approved,
      [Status.Pending ]: TranslatedStatus.Pending,
      [Status.Rejected]: TranslatedStatus.Rejected,
      [Status.Expired]: TranslatedStatus.Expired,
      [Status.Delete]: TranslatedStatus.Delete,
    };

    return translationMap[status];
  }

}
